import React from "react";
// import bg1 from "../../assets/background/slider-1 1.png";
// import bg2 from "../../assets/background/slider-2 1.png";
// import bg3 from "../../assets/background/image 35.png";
// import bg4 from "../../assets/background/slider-3 1.png";
import contentimg1 from "../../assets/content-img/image 13.png";
import contentimg2 from "../../assets/content-img/image 39.png";

import card1 from "../../assets/card-img/card1.png";
import card2 from "../../assets/card-img/maintenance 1.png";
import card3 from "../../assets/card-img/building 1.png";
import card4 from "../../assets/card-img/house-rental 1.png";
import card5 from "../../assets/card-img/gear 1.png";
import card6 from "../../assets/card-img/inspecting 1.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { motion } from "framer-motion";
import Slider from "react-slick";

import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import video from "../../assets/bg-video.mp4";
import client1 from "../../assets/client-logo/image 20.png";
import client2 from "../../assets/client-logo/image 21.png";
import client3 from "../../assets/client-logo/image 22.png";
import client4 from "../../assets/client-logo/image 23.png";
import client5 from "../../assets/client-logo/image 24.png";
import client6 from "../../assets/client-logo/image 25.png";
import client7 from "../../assets/client-logo/image 26.png";
import Certification from "../certification/Certification";
import VisionSection from "../vision/VisionSection";
import { Link } from "react-router-dom";
// import { TypeAnimation } from "react-type-animation";

const clientImage = [
  {
    img: client1,
    id: "11",
  },
  {
    img: client2,
    id: "12",
  },
  {
    img: client3,
    id: "13",
  },
  {
    img: client4,
    id: "14",
  },
  {
    img: client5,
    id: "15",
  },
  {
    img: client6,
    id: "16",
  },
  {
    img: client7,
    id: "17",
  },
];

function Home() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="home">
        <div className="hero">
          <video autoPlay loop muted id="video">
            <source src={video} type="video/mp4" />
          </video>
          <div className="overlay10"></div>
          <div className="hero-slider-content">
            <Carousel pause={false}>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    GULF SHORE GROUP we provide high quality service
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    GULF SHORE GROUP 17 years of Engineering Experience
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        {/* <div className="home-slider">
          <Carousel fade>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg1}
                text="First slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  when services matters your choice is simple
                </div>

                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg2}
                text="Second slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  GULF SHORE GROUP we provide high quality service
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg3}
                text="Third slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  when services matters your choice is simple
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg4}
                text="Fourth slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  GULF SHORE GROUP 17 years of Engineering Experience
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
          </Carousel>
        </div> */}
        {/* <TypeAnimation
                      sequence={[
                        "when services matters your choice is simple",
                        1000,
                      ]}
                      wrapper="span"
                      speed={50}
                      style={{ fontSize: "2em", display: "inline-block" }}
                      repeat={Infinity}
                    /> */}

        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-1-img-section">
                <Carousel pause={false}>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg1} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg2} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>

            <Col>
              <div className="content-1">
                <div className="content-1-details">
                  <h1 className="heading">about the company</h1>
                </div>
                <div className="content-1-details">
                  <p className="para">
                    We're obsessed with modern, fast, eco-friendly and reliable
                    construction technologies. We're an early adopter of Pre
                    Fabricated Buildings (PEB) and Light Gauge Framing System
                    (LGSF). An End to End player, and our expertise and assist
                    you with Project Planning, Procurement, Manufacturing,
                    Production, Erection and Commissioning.
                  </p>
                </div>
                <div className="content-1-details">
                  <Link to={"aboutus"}>
                    <button className="content-btn">MORE INFO</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="section-heading">
              <div className="heading-text">Our Services</div>
              <div className="heading-desc">An overview of what we do.</div>
            </div>
          </Row>

          <div className="home-section2">
            <div className="card-section">
              <div className="card">
                <div className="card-logo">
                  <Link to={"/oilandgas"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>
                      <img className="card-img" src={card1} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">OIL & GAS</div>

                <Link className="view-btn" to={"/oilandgas"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/engineering"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card2} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">ENGINEERING</div>
                <Link className="view-btn" to={"/engineering"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/pre-engineered"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card3} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">pre engineered building</div>
                <Link className="view-btn" to={"/pre-engineered"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/off-shore-on-shore-rental"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card4} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">offshore/onshore rental</div>
                <Link className="view-btn" to={"/off-shore-on-shore-rental"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/maintanance-refurbishment"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card5} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">maintenance & refurbishment</div>
                <Link className="view-btn" to={"/maintanance-refurbishment"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/load-testing-and-inspection"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card6} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">load testing & inspections</div>
                <Link className="view-btn" to={"/load-testing-and-inspection"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
            </div>
          </div>
        </Container>

        <VisionSection />

        <div>
          <div className="home-section4">
            <div className="section-heading">
              <div className="heading-text">Take a look at our clients</div>
              <div className="heading-desc">
                We did a great job with these companies. You can be next to work
                with!
              </div>
            </div>

            <div className="client-slider">
              <Slider className="slider-css" {...settings}>
                {clientImage.map((i) => (
                  <div className="slider" key={i.id}>
                    <img src={i.img} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <Certification />
      </div>
    </motion.div>
  );
}

export default Home;
