import React from "react";
import contentLogo from "../../assets/content-logo/Frame 4 1.png";
import contentLogo2 from "../../assets/content-logo/Frame 5 1.png";
import { motion } from "framer-motion";

import "./VisionSection.css";
function VisionSection() {
  return (
    <div>
      <div className="home-section3">
        <div className="section-heading">
          <div className="heading-text">vission & mission</div>
          <div className="heading-desc">
            what are we doing, and why are we doing it.
          </div>
        </div>
        <div className="section-content3">
          <div className="content3">
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
              className="v-icon"
            >
              <img src={contentLogo} alt="" />
            </motion.div>
            <div className="content3-heading">our vision</div>
            <div className="line"></div>
            <div className="content3-detail">
              To become the spearheads of revolutionary offshore / mechanical
              turnkey project management techniques, ensure uniform quality
              standards to our client, and expand our service portfolio by
              investing in technology and training for the looming engineers.
            </div>
          </div>

          <div className="content3">
            <motion.div
              animate={{ rotate: [20, -30, 20] }}
              whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
              transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
              className="v-icon"
            >
              <img src={contentLogo2} alt="" />
            </motion.div>
            <div className="content3-heading">our vision</div>
            <div className="line"></div>
            <div className="content3-detail">
              To become the spearheads of revolutionary offshore / mechanical
              turnkey project management techniques, ensure uniform quality
              standards to our client, and expand our service portfolio by
              investing in technology and training for the looming engineers.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionSection;
