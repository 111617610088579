import React from "react";
import logoWhite from "../../assets/logo/GULF SHORE LOGO PNG 4.png";
import "./Footer.css";
import facebookicon from "../../assets/face.png";
import instagarmicon from "../../assets/instagram.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <div className="logofooter">
        <img src={logoWhite} alt="" />
      </div>
      <div className="menufooter">
        <div>
          <ul className="menu-list">
            <li>
              <Link className="menu-list-link" to={"/"}>
                home
              </Link>{" "}
            </li>
            <li>
              <Link className="menu-list-link" to={"/aboutus"}>
                {" "}
                about us
              </Link>{" "}
            </li>
            <li>
              <Link className="menu-list-link" to={"/contactus"}>
                contact us
              </Link>
            </li>
            <li>
              <Link className="menu-list-link" to={"/iso-certifications"}>
                certifications
              </Link>
            </li>
          </ul>
        </div>

        <div className="iconfooter">
          <div>
            <img src={instagarmicon} alt=" instagram icon" />
          </div>
          <div>
            <img src={facebookicon} alt="facebook icon" />
          </div>
        </div>
      </div>
      <div className="contactfooter">
        <div className="footerHeading">address</div>
        <div className="footerDetail">
          Gulf Shore Group Building No. 15, Street 3080, Zone 91, Birkat Al
          Awamer, Doha,Qatar
        </div>
        <div className="footerHeading">phone</div>
        <div className="footerDetail">
          +974 7447 7650 \ +974 5512 9692 \ +974 7443 3090
        </div>
        <div className="footerHeading">e-mail</div>
        <div className="footerDetail">info@gulfshore-group.com</div>
      </div>
    </div>
  );
}

export default Footer;
