import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUs.css";
import VisionSection from "../vision/VisionSection";
import Certification from "../../components/certification/Certification";
import { motion } from "framer-motion";

function AboutUs() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <Container>
        <Row>
          <Col>
            <div className="oil-banner7">
              <div className="oil-title7">
                <div className="about-us-heading"> the company</div>
              </div>
              <div className="overlay7"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <div className="about-us-decs">
              Gulf Shore is an ISO 9001:2015 certified company headquartered in
              Qatar. It was conceived by a group of talented engineers with an
              aim to revolutionize the Steel and offshore industry. From
              engineering to detail design, manufacturing, fabrication, testing
              and commissioning of offshore/onshore installations and project
              management, we find viable, practical, time bound and economical
              solutions for every client. Given the expertise, qualifications
              and years of professional experience with every team member, gulf
              shore Offshore offers a better perspective and a competitive edge
              while handling your business.
            </div>
          </div>
        </Row>
      </Container>

      <VisionSection />
      <Certification />
    </motion.div>
  );
}

export default AboutUs;
